<template>
  <div class="dashboard">
    <div class="dashboard__container">
      <!-- <Loader v-if="!group" /> -->
      <div class="dashboard__container--header">
        <div>
          <h1>Dashboard</h1>
          <span class="flex mb-3 flex-wrap">
            <router-link :to="{name: 'myOnboarding'}">
              <button class="btn btn__small" v-bind:class="{ 'btn__dark': isOnboarding, 'btn__outlined': !isOnboarding }">My Onboarding</button>
            </router-link>
            <router-link :to="{name: 'myActive'}">
              <button class="btn btn__small ml-3" v-bind:class="{ 'btn__dark': isActive, 'btn__outlined': !isActive }">My Active</button>
            </router-link>
            <router-link :to="{name: 'myPipeline'}">
              <button class="btn btn__small ml-3" v-bind:class="{ 'btn__dark': isPipeline, 'btn__outlined': !isPipeline }">My Pipeline</button>
            </router-link>
            <router-link :to="{name: 'myUserWatchlist'}">
              <button class="btn btn__small ml-3" v-bind:class="{ 'btn__dark': isUsers, 'btn__outlined': !isUsers }">My Users</button>
            </router-link>
            <router-link :to="{name: 'myOrderWatchlist'}">
              <button class="btn btn__small ml-3" v-bind:class="{ 'btn__dark': isOrders, 'btn__outlined': !isOrders }">My Orders</button>
            </router-link>
            <router-link :to="{name: 'myGroupWatchlist'}">
              <button class="btn btn__small ml-3" v-bind:class="{ 'btn__dark': isGroups, 'btn__outlined': !isGroups }">My Groups</button>
            </router-link>
            
          </span>
        </div>
        <span class="flex align-center">
          <button class="btn btn__outlined btn__small mb-3" @click="goBack"><i class="fas fa-arrow-left"></i></button>
        </span>
        
      </div>
      <router-view :key="$route.params.id" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import router from '@/router'

export default {
  name: 'dashboardHome',
  computed: {
    ...mapState(['currentUser', 'userProfile']),
    // client() {
    //   return this.clientInfo
    // },
    // clean() {
    //   return (!this.groupUsers || this.groupUsers.length == 0)
    // },
    // sortedUsers() {
    //   if (this.groupUsers && this.groupUsers.length >= 1) {
    //     return this.groupUsers.sort((a, b) => a.firstName.localeCompare(b.firstName))
    //   } else {
    //     return []
    //   }
      // return this.groupUsers.sort((a, b) => a.firstName.localeCompare(b.firstName))
        // return ((a.firstName && a.firstName.toLowerCase()) - (b.firstName && b.firstName.toLowerCase()));
      // })
    // },
    // isContacts() {
    //   return this.$route.name == 'clientContacts';
    // },
    // isEdit() {
    //   return this.$route.name == 'groupEdit';
    // },
    // // isKaela() {
    // //   return this.currentUser.uid == 'YjqnBY7AbUZuZWcinHab9TNiH9n1'
    // // },
    isUsers() {
      return this.$route.name == 'myUserWatchlist';
    },
    isOrders() {
      return this.$route.name == 'myOrderWatchlist';
    },
    isPipeline() {
      return this.$route.name == 'myPipeline';
    },
    isOnboarding() {
      return this.$route.name == 'myOnboarding';
    },
    isActive() {
      return this.$route.name == 'myActive';
    },
    isGroups() {
      return this.$route.name == 'myGroupWatchlist';
    },
    // isOwner() {
    //   return this.group.owner == this.currentUser.uid
    // },
    // isAdmin() {
    //   // if (this.group && this.group.admins) {
    //   //   return (this.group.admins.some(person => person.userId == this.currentUser.uid) || this.currentUser.uid == 'DN9vVu3qDhPdpBOUpIb8GopOuhY2')
    //   // }
    //   return true
    // },
    // isUser() {
    //   if (this.group && this.group.users) {
    //     return this.group.users.some(person => person.userId == this.currentUser.uid)
    //   }
    // },
    // isWatchList() {
    //   if (this.userProfile && this.userProfile.groupWatchList && this.userProfile.groupWatchList.includes(this.group.id)) {
    //     return true
    //   } else {
    //     return false
    //   }
    // },
  },
  // created () {
  //   this.$store.dispatch("getGroupFromId", this.$route.params.id);
  // },
  components: {
    Loader,
  },

  methods: {
    // addWatch() {
    //   this.$store.dispatch('watchGroup', this.$route.params.id)
    // },
    // removeWatch() {
    //   this.$store.dispatch('unWatchGroup', this.$route.params.id)
    // },
    goBack() {
      router.go(-1)
    },
  },
  // beforeDestroy () {
  //   console.log(this)
  // }
}
</script>